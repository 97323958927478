import styled from "styled-components";
import PropTypes from "prop-types";

const StyledExpandReactText = styled.div`
  ${({ theme: { space }, invert}) => `
    top: ${ space.small };
    ${invert ? "right" : "left"}: ${ space.small }; 
    position: absolute;
    z-index: 2;
    // keeps this from disappearing when the parent element resizes in safari
    transform: translate3d(0px, 0px, 0px);
  `}
`;


StyledExpandReactText.propTypes = {
  invert: PropTypes.bool,
};

StyledExpandReactText.defaultProps = {
  invert: false,
};

export default StyledExpandReactText;
