import classNames from "classnames";
import styled from "styled-components";

const CloseButtonWrapper = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "discord-reacts-modal__detail__close-button-wrapper"
    )
  };
})`
  ${({ theme: { space } }) => `
    margin: ${ space.small } 0;
    pointer-events: none;
    position: sticky;
    top: ${ space.small };
    z-index: 1000;
  `}
`;

export default CloseButtonWrapper;
