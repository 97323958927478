import React from "react";

import StyledHeader from "./views/Header";
import ReactTagText from "./views/ReactTagText";
import ReactTitleText from "./views/ReactTitleText";

const Header = props => {
  const { invert, react } = props

  return (
    <StyledHeader invert={invert}>
      <ReactTitleText>
        { react.react }
      </ReactTitleText>
      <ReactTagText>
        (New Models React)
      </ReactTagText>
    </StyledHeader>
  );
};

export default Header;
