import React from "react";

import { useDiscordReactsData } from "@webdex/Graph/hooks";

import DiscordReact from "./components/DiscordReact";
import StyledDiscordReactsIndex from "./views/DiscordReactsIndex";
import DiscordReactsIndexInnerWrapper from
  "./views/DiscordReactsIndexInnerWrapper";

const DiscordReactsIndex = props => {
  const { invert, onSelect } = props;

  const discordReacts = useDiscordReactsData();

  return (
    <StyledDiscordReactsIndex>
      <DiscordReactsIndexInnerWrapper>
        {
          discordReacts.map((discordReact) => (
            <DiscordReact
              key={discordReact._id}
              react={discordReact}
              onSelect={onSelect}
              invert={invert}
            />
          ))
        }
      </DiscordReactsIndexInnerWrapper>
    </StyledDiscordReactsIndex>
  );
};

DiscordReactsIndex.defaultProps = {
  invert: false
};

export default DiscordReactsIndex;
