import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

import { Row } from "@webdex/UI/Grid";

const Body = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "discord-reacts-modal__detail__react-detail__body"
    )
  };
})`
  ${({ theme: { chrome, space } }) => `
    display: flex;
    flex: 1;
    min-height: 100%;
    padding-bottom: ${ chrome.mobile.footerHeight };
    padding-top: ${ space.small };

    & ${ Row } {
      flex: 1;
    }
  `}

  ${media.sm`
    ${ ({ theme: { chrome } }) => `
      && {
        padding-bottom: ${ chrome.footerHeight };
      }
    `}
  `}
`;

Body.defaultProps = {};

export default Body;
