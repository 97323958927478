import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";
import { KeyboardFocusContainer } from "@zendeskgarden/container-keyboardfocus";

const StyledToggleReacts = styled.div.attrs((props) => {
  const { className, active, focused, forceUnderline, hovered } = props;

  return {
    className: classNames(className, "webdex-ui-react-toggle", {
      "webdex-ui-react-toggle--is-active": active,
      "webdex-ui-react-toggle--is-focused": focused,
      "webdex-ui-react-toggle--is-hovered": hovered,
      "webdex-ui-react-toggle--force-underline": forceUnderline,
    }),
  };
})`
  ${({ theme: { colors, fontSizes, fontWeights, radii, space }, invert }) => `
    border: none; 
    margin: 0;
    overflow: visible;
    text-transform: none;
    z-index: 999; 
    position: fixed; 
    width: 400px;
    max-width: calc(100vw - 10px);
    height: 200px;
    ${invert ? 'left: 5px;' : 'right: 5px;' }
    bottom: 20px; 
    border-radius: ${radii.reactsToggle};
    cursor: pointer;
    outline: none;
    overflow: hidden;
    text-decoration: none;
    will-change: left, background-color, color;

    color: ${invert ? colors.white : colors.black};
    background: ${invert ? colors.black : colors.white};
    font-size: ${fontSizes.small};
    font-weight: ${fontWeights.bold};

    transform-origin: ${invert ? "left bottom" : "right bottom"};
    transition: transform 0.15s ease-in-out;
    transform: scale(0.75);

    &:active, &.webdex-ui__react-toggle--is-active,
    &:hover, &.webdex-ui__react-toggle--is-hovered
    {
      text-decoration: underline;
      transform: scale(1.0);
    }

    // :focus managed by KeyboardFocusContainer
    &.webdex-ui-react-toggle--is-focused
    {
      text-decoration: underline;
      transform: scale(1.0);
    }

    &.webdex-ui-react-toggle--force-underline {
      text-decoration: underline;
    }
  `}
`;

const ToggleReacts = React.forwardRef(
  ({ open, transitionMs, focused, invert, ...other }, ref) => (
    <KeyboardFocusContainer>
      {({ getFocusProps, keyboardFocused }) => (
        <StyledToggleReacts
          invert={invert}
          {...getFocusProps({
            ref,
            ...other,
            focused: focused || keyboardFocused,
          })}
        />
      )}
    </KeyboardFocusContainer>
  )
);

ToggleReacts.propTypes = {
  active: PropTypes.bool,
  focused: PropTypes.bool,
  forceUnderline: PropTypes.bool,
  hovered: PropTypes.bool,
  invert: PropTypes.bool,
};

ToggleReacts.defaultProps = {
  active: false,
  focused: false,
  forceUnderline: false,
  hovered: false,
  invert: false,
};

export default ToggleReacts;
