import React from "react";

import { useDiscordReactPreviewData } from "@webdex/Graph/hooks";

import PlayerInnerWrapper from "./views/PlayerInnerWrapper";
import PlayerWrapper from "./views/PlayerWrapper";
import StyledToggleReacts from "./views/ToggleReacts";
import PopoutIcon from "./views/PopoutIcon";
import StyledExpandReactText from "./views/StyledExpandReactText";

const DiscordReactsModalTogglePane = (props) => {
  const { invert, onClick } = props;

  const {
    blackBackgroundVersion: {
      asset: { url: blackBackgroundVersionSrc },
    },
    blackBackgroundVersionHeight,
    blackBackgroundVersionWidth,
    whiteBackgroundVersion: {
      asset: { url: whiteBackgroundVersionSrc },
    },
    whiteBackgroundVersionHeight,
    whiteBackgroundVersionWidth,
  } = useDiscordReactPreviewData();

  const handleClick = () => {
    onClick();
  };

  const renderVideo = () => {
    let videoHeight;
    let videoWidth;
    let videoSrc;

    if (invert) {
      videoHeight = blackBackgroundVersionHeight;
      videoWidth = blackBackgroundVersionWidth;
      videoSrc = blackBackgroundVersionSrc;
    } else {
      videoHeight = whiteBackgroundVersionHeight;
      videoWidth = whiteBackgroundVersionWidth;
      videoSrc = whiteBackgroundVersionSrc;
    }

    return (
      <PlayerWrapper height={videoHeight} width={videoWidth} thumbnailSrc={""}>
        <PlayerInnerWrapper>
          <video muted autoPlay playsInline loop controls={false}>
            <source src={videoSrc} type="video/mp4" />
          </video>
        </PlayerInnerWrapper>
      </PlayerWrapper>
    );
  };

  const renderText = (invert) => {
    if (invert) {
      return (
        <React.Fragment>
          Expand Reacts
          <PopoutIcon invert={invert} />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <PopoutIcon invert={invert} />
          Expand Reacts
        </React.Fragment>
      );
    }
  };

  return (
    <StyledToggleReacts role="link" onClick={handleClick} invert={invert}>
      <StyledExpandReactText invert={invert}>
        {renderText(invert)}
      </StyledExpandReactText>
      {renderVideo()}
    </StyledToggleReacts>
  );
};

export default DiscordReactsModalTogglePane;
