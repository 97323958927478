import React from "react";

import isWindow from "dom-helpers/query/isWindow";
import ownerDocument from "dom-helpers/ownerDocument";
import ownerWindow from "dom-helpers/ownerWindow";
import css from "dom-helpers/style";
import getScrollbarSize from "dom-helpers/util/scrollbarSize";

import StyledBackdrop from "./views/Backdrop";

class Backdrop extends React.Component {
  componentDidMount() {
    this.setModalMountStyles();
  }

  componentWillUnmount() {
    this.setModalUnmountStyles();
  }

  setModalMountStyles = () => {
    const bodyElement = document.querySelector("body");

    if (this.isOverflowing(bodyElement)) {
      const scrollbarSize = getScrollbarSize();
      const bodyPaddingRight =
        parseInt(css(bodyElement, "paddingRight") || 0, 10);

      this.previousBodyPaddingRight = bodyElement.style.paddingRight;
      bodyElement.style.paddingRight = `${bodyPaddingRight + scrollbarSize}px`;
    }

    this.previousBodyOverflow = bodyElement.style.overflow;
    bodyElement.style.overflow = "hidden";
  };

  setModalUnmountStyles = () => {
    const bodyElement = document.querySelector("body");

    bodyElement.style.overflow = this.previousBodyOverflow;
    bodyElement.style.paddingRight = this.previousBodyPaddingRight;
  };

  isOverflowing = element => {
    const doc = ownerDocument(element);
    const win = ownerWindow(doc);

    const isBody = element && element.tagName.toLowerCase() === "body";

    if (!isWindow(doc) && !isBody) {
      return element.scrollHeight > element.clientHeight;
    }

    const style = win.getComputedStyle(doc.body);
    const marginLeft = parseInt(style.getPropertyValue("margin-left"), 10);
    const marginRight = parseInt(style.getPropertyValue("margin-right"), 10);

    return marginLeft + doc.body.clientWidth + marginRight < win.innerWidth;
  };

  render() {
    const {
      children,
      ...backdropProps
    } = this.props;

    return (
      <StyledBackdrop {...backdropProps}>
        { children }
      </StyledBackdrop>
    );
  }
}

export default Backdrop;
