import classNames from "classnames";
import styled from "styled-components";

const PlayerInnerWrapper = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "chrome__toggle-discord-reacts__player-inner-wrapper"
    )
  };
})`
  ${() => `
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;

    > video {
      height: 100%;
      object-fit: cover;
      width: 100%;
      margin-top: 20px
    }

    > canvas {
      display: inline-block!important;
      height: 100%!important;
      object-fit: cover!important;
      width: 100%!important;
    }
  `}
`;

export default PlayerInnerWrapper;
