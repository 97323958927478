import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// const SpacedSVG = styled.svg``;

const SpacedSVG = styled.svg`
  ${({ invert }) => `
    margin-left: 0.5em;
    margin-right: 0.5em;
    ${invert ? "transform: scaleX(-1)" : ""}
  `}
`;

const PopoutIcon = ({ width, height, invert, fillColor }) => {
  if (invert) {
    fillColor = "#FFFFFF";
  }
  return (
    <SpacedSVG
      width={width}
      height={height}
      invert={invert}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.571429 0L6.28571 0V1.14286L1.95098 1.14286L7.83263 7.02451L7.02451 7.83263L1.14286 1.95098L1.14286 6.28571H0L0 0.571429C0 0.255837 0.255837 0 0.571429 0Z"
        fill={fillColor}
      />
      <path
        d="M8 1.14286L10.8571 1.14286V10.8571H1.14286L1.14286 8H0L0 11.4286C0 11.7442 0.255837 12 0.571429 12H11.4286C11.7442 12 12 11.7442 12 11.4286V0.571429C12 0.255837 11.7442 0 11.4286 0L8 0V1.14286Z"
        fill={fillColor}
      />
    </SpacedSVG>
  );
};

SpacedSVG.propTypes = {
  invert: PropTypes.bool
}

SpacedSVG.defaultProps = {
  invert: false
}

PopoutIcon.propTypes = {
  invert: PropTypes.bool,
  fillColor: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PopoutIcon.defaultProps = {
  invert: false,
  fillColor: "#000000",
  width: 12,
  height: 12,
};

export default PopoutIcon;
