import classNames from "classnames";
import styled from "styled-components";

import { ResponsiveText } from "@webdex/UI/Typography";

const ReactTitleText = styled(ResponsiveText).attrs(props => {
  const {
    className
  } = props;

  return {
    as: "span",
    className: classNames(
      className,
      "discord-reacts-modal__detail__react-detail__header__react-title-text"
    )
  };
})`
  vertical-align: bottom;
`;

ReactTitleText.defaultProps = {
  lg: "xxLarge",
  md: "xLarge",
  sm: "xLarge",
  xs: "large"
};

export default ReactTitleText;
