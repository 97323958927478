import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";

const TRANSITION_NAME = `modal__backdrop`;

const StyledBackdrop = styled.div.attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames("discord-reacts-modal__backdrop", className)
    };
  }
)`
  ${({ transitionMs }) => `
    align-items: center;
    backdrop-filter: blur(9px);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity ${ transitionMs }ms cubic-bezier(0.39, 0.575, 0.565, 1);
    will-change: opacity;
    z-index: 1002;

    &.${ TRANSITION_NAME }-appear,
    &.${ TRANSITION_NAME }-enter,
    &.${ TRANSITION_NAME }-exit.${ TRANSITION_NAME }-exit-active {
      // Will apply opacity to all children.
      opacity: 0.01;
    };

    &.${ TRANSITION_NAME }-exit-done {
      // Will apply opacity to all children.
      opacity: 0.0;
    };

    &.${ TRANSITION_NAME }-appear.${ TRANSITION_NAME }-appear-active,
    &.${ TRANSITION_NAME }-enter.${ TRANSITION_NAME }-enter-active,
    &.${ TRANSITION_NAME }-appear-done,
    &.${ TRANSITION_NAME }-enter-done,
    &.${ TRANSITION_NAME }-exit {
      // Will apply opacity to all children.
      opacity: 1;
    };
  `}
`;

StyledBackdrop.propTypes = {};

StyledBackdrop.defaultProps = {};

const Backdrop = props => {
  const { children, entered, transitionMs, ...passdownProps } = props;

  return (
    <CSSTransition
      appear
      classNames={{
        appear: `${ TRANSITION_NAME }-appear`,
        appearActive:`${ TRANSITION_NAME }-appear-active`,
        // Keep this. Prevents `react-transition-group` from adding
        // `undefined` to class name list.
        appearDone: `${ TRANSITION_NAME }-appear-done`,
        enter: `${ TRANSITION_NAME }-enter`,
        enterActive: `${ TRANSITION_NAME }-enter-active`,
        enterDone: `${ TRANSITION_NAME }-enter-done`,
        exit: `${ TRANSITION_NAME }-exit`,
        exitActive: `${ TRANSITION_NAME }-exit-active`,
        exitDone: `${ TRANSITION_NAME }-exit-done`,
      }}
      in={ entered }
      timeout={{
        appear: transitionMs, enter: transitionMs, exit: transitionMs
      }}
    >
      <StyledBackdrop transitionMs={ transitionMs } { ...passdownProps }>
        { children }
      </StyledBackdrop>
    </CSSTransition>
  );
};

Backdrop.propTypes = {
  entered: PropTypes.bool.isRequired,
  transitionMs: PropTypes.number.isRequired
};

Backdrop.defaultProps = {};

export default Backdrop;
