import classNames from "classnames";
import styled from "styled-components";

const DiscordReactName = styled.div.attrs((props) => {
  const {
    className,
    invert
  } = props;

  return {
    className: classNames(
      className,
      "discord-reacts-modal__discord-react__name", {
        "discord-reacts-modal__discord-react__name--invert": invert
      }
    )
  };
})`
  ${({ theme: { colors } }) => `
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    text-align:left;
    color: ${ colors.black };
    width: 100px;
    max-width: 100%;
    overflow: visible;

    &.discord-reacts-modal__discord-react__name--invert {
      color: ${ colors.white };
    }
  `}
`;

DiscordReactName.propTypes = {};

DiscordReactName.defaultProps = {};

export default DiscordReactName;
