import React from "react";

import { ResponsiveText } from "@webdex/UI/Typography";

import StyledGlossaryTerm from "./views/GlossaryTerm";
import GlossaryTermLink from "./views/GlossaryTermLink";

const GlossaryTerm = props => {
  const { glossaryTerm } = props;

  return (
    <StyledGlossaryTerm>
      <GlossaryTermLink
        to={`/glossary/${glossaryTerm.slug.current}`}
        state={{ disableScrollUpdate: true }}
      >
        <ResponsiveText
          as="span"
          lg="xLarge"
          md="xLarge"
          sm="large"
          xs="medium"
        >
          “{ glossaryTerm.term }”
        </ResponsiveText>
      </GlossaryTermLink>
    </StyledGlossaryTerm>
  );
};

export default GlossaryTerm;
