import styled from "styled-components";
import classNames from "classnames"

import { Button } from "@webdex/UI/Button";

const StyledCloseButton = styled(Button).attrs((props) => {
  const { className, invert } = props;

  return {
    className: classNames(
      className,
      "chrome__discord-reacts-modal__close-button", {
        "chrome__discord-reacts-modal__close-button--invert": invert
      }
    )
  };
})`
  ${({ theme: { colors } }) => `
    z-index: 2;
    width: 100%;
    color: ${ colors.white };
    background-color: ${ colors.black };
    pointer-events: all;

    &.chrome__discord-reacts-modal__close-button--invert {
      color: ${ colors.black };
      background-color: ${ colors.white };
    }
  `}
`;

export default StyledCloseButton;
