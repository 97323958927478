import React from "react";
import BlockContent from "@sanity/block-content-to-react";

import { Anchor } from "@webdex/UI/Button";
import { ResponsiveParagraph } from "@webdex/UI/Paragraph";

import { Row, Col } from "@webdex/UI/Grid";

import ReactImagePane from "./components/ReactImagePane";
import StyledBody from "./views/Body";

const serializers = {
  types: {
    block: ({ children }) => (
      <ResponsiveParagraph lg="large" sm="medium" xs="small" withMeasure>
        { children }
      </ResponsiveParagraph>
    )
  },
  marks: {
    link: ({ children, mark }) => (
      mark.newTab ? (
        <Anchor href={mark.href} target="_blank" rel="noopener noreferrer">
          { children }
        </Anchor>
      ) : (
        <Anchor href={mark.href}>
          { children }
        </Anchor>
      )
    )
  }
};

const Body = props => {
  const { react } = props

  return (
    <StyledBody>
      <Row>
        <Col xl="5" lg="5" md="4" sm="8" xs="8">
          <BlockContent
            blocks={react._rawDefinition}
            serializers={serializers}
          />
        </Col>
        <Col xl="3" lg="3" md="4" sm="8" xs="8">
          <ReactImagePane react={react}/>
        </Col>
      </Row>
    </StyledBody>
  );
};

export default Body;
