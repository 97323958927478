import React, { useMemo } from "react";

import { ResponsiveText } from "@webdex/UI/Typography";

import GlossaryTerm from "./components/GlossaryTerm";
import StyledGlossary from "./views/Glossary";
import GlossaryTermGroupHeader from "./views/GlossaryTermGroupHeader";

const Glossary = props => {
  const { glossaryTerms, stickyOffset } = props;

  const glossaryTermsArray = glossaryTerms.edges;

  const sortedAndGrouped = useMemo(() => {
    // Regex to extract term from object, capturing the important part of the
    // string for sorting (e.g.; "Cat" within "Cat", or "Dog" within "The Dog").
    const termRegex =
      /^(The\s|the\s|A\s|a\s|An\s|an\s|\s*)?(?<matchTerm>.*)/;
    // Make copy of glossary terms array, as `sort` mutates.
    const copy = [...glossaryTermsArray];

    // First, sort our glossary term objects by their terms "smartly" (see
    // above), then reduce into an object where glossary term objects are
    // grouped - again, smartly - by their term into alphabetical groups.
    const sortedAndGrouped = copy.sort((a, b) => {
      const { groups: { matchTerm: aTerm } } = termRegex.exec(a.node.term);
      const { groups: { matchTerm: bTerm } } = termRegex.exec(b.node.term);

      return aTerm.toLowerCase().localeCompare(bTerm.toLowerCase());
    }).reduce((memo, glossaryTerm) => {
      const {
        groups: { matchTerm: term }
      } = termRegex.exec(glossaryTerm.node.term);

      const letter = term[0].toLowerCase();

      if (!memo[letter]) {
        memo[letter] = { letter: letter, children: [glossaryTerm] };
      } else {
        memo[letter].children.push(glossaryTerm);
      }

      return memo;
    }, {});

    return Object.values(sortedAndGrouped);
  }, [glossaryTermsArray]);

  return (
    <StyledGlossary>
      {sortedAndGrouped.map((alphabeticalGroup, i) => (
        <div key={`home__glossary__glossary-term-group-k-${i}`}>
          <GlossaryTermGroupHeader stickyOffset={stickyOffset}>
            <ResponsiveText
              as="span"
              uppercase
              lg="large"
              md="large"
              sm="medium"
              xs="small"
            >
              { alphabeticalGroup.letter }
            </ResponsiveText>

          </GlossaryTermGroupHeader>

          {alphabeticalGroup.children.map(({ node: glossaryTerm }, i) => (
            <GlossaryTerm
              key={`home__glossary__glossary-term-k-${i}`}
              glossaryTerm={glossaryTerm}
            />
          ))}
        </div>
      ))}
    </StyledGlossary>
  );
};

export default Glossary;
