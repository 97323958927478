import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const ReactImagePane = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "discord-reacts-modal__detail__react-detail__body__react-image-pane"
    )
  };
})`
  ${({ theme: { space }}) => `
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    padding: ${ space.large };
  `}

  ${media.sm`
    ${ ({ theme: { space } }) => `
      && {
        padding: ${ space.medium };
      }
    `}
  `}
`;

ReactImagePane.defaultProps = {};

export default ReactImagePane;
