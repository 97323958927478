import classNames from "classnames";
import styled from "styled-components";

const ReactImageWrapper = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "discord-reacts-modal__detail__react-detail__body__react-image-pane__image-wrapper"
    )
  };
})`
  ${({ theme: { colors, fonts, fontSizes, lineHeights, space }, reactCode }) => `
    display: inline-flex;
    position: relative;

    &:after {
      background: ${ colors.yellow };
      border-radius: 5px;
      color: ${ colors.black };
      content: "${ reactCode }";
      font-family: ${ fonts.monospace };
      font-size: ${ fontSizes.xSmall };
      line-height: ${ lineHeights.xSmall };
      padding: 0 ${ space.xSmall };
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(0, -150%);
    }
  `}
`;

export default ReactImageWrapper;
