import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

import { Row } from "@webdex/UI/Grid";

const Body = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "home-page__body"
    )
  };
})`
  ${({ theme: { chrome } }) => `
    display: flex;
    flex: 1;
    padding-bottom: ${ chrome.mobile.footerHeight };

    > ${ Row } {
      flex: 1;
    }
  `}

  ${media.sm`
    ${ ({ theme: { chrome } }) => `
      && {
        padding-bottom: ${ chrome.footerHeight };
      }
    `}
  `}
`;

Body.defaultProps = {};

export default Body;

