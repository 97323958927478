import React from "react";
import { graphql } from "gatsby";

import HomePageComponent from "@webdex/Pages/Home";

const Home = props => {
  const { data } = props;

  return (
    <HomePageComponent data={data}/>
  );
};

export const query = graphql`
  query HomePage {
    homePage: sanityHomePage {
      ...fieldsOnHomePage
    }
    glossaryTerms: allSanityGlossaryTerm(
      sort: {
        fields: [term]
        order: ASC
      }
    ) {
      edges {
        node {
          _id
          term
          slug {
            current
          }
        }
      }
    }
  }
`;

export default Home;
