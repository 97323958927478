import React from "react";

import StyledBackdrop from "./views/Backdrop";

const Backdrop = props => {
  const {
    children,
    ...backdropProps
  } = props;

  return (
    <StyledBackdrop {...backdropProps}>
      { children }
    </StyledBackdrop>
  );
};

export default Backdrop;
