import React from "react";

import { Col } from "@webdex/UI/Grid";

import Body from "./components/Body";
import Header from "./components/Header";
import StyledReactDetail from "./views/ReactDetail";
import ReactDetailBodyRow from "./views/ReactDetailBodyRow";
import ReactDetailHeaderRow from "./views/ReactDetailHeaderRow";

const ReactDetail = props => {
  const { invert, react } = props;

  return (
    <StyledReactDetail invert={invert}>
      <ReactDetailHeaderRow>
        <Col col="8">
          <Header invert={invert} react={react}/>
        </Col>
      </ReactDetailHeaderRow>
      <ReactDetailBodyRow>
        <Col col="8">
          <Body react={react}/>
        </Col>
      </ReactDetailBodyRow>
    </StyledReactDetail>
  );
};

export default ReactDetail;
