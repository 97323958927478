import React, { forwardRef } from "react";

import { Row, Col } from "@webdex/UI/Grid";

import Glossary from "./components/Glossary";
import StyledBody from "./views/Body";

const Body = forwardRef((props, ref) => {
  const { glossaryTerms, stickyOffset } = props;

  return (
    <StyledBody ref={ref}>
      <Row>
        <Col col="8">
          <Glossary glossaryTerms={glossaryTerms} stickyOffset={stickyOffset}/>
        </Col>
      </Row>
    </StyledBody>
  );
});

export default Body;
