import classNames from "classnames";
import styled from "styled-components";

const DiscordReact = styled.button.attrs((props) => {
  const { className } = props;

  return {
    className: classNames(className, "discord-reacts-modal__discord-react")
  };
})`
  ${() => `
    background: none;
    border: none;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100px;
    cursor: pointer;
    will-change: filter;

    &:hover > div:after {
      opacity: 1;
    }

    @media (max-width: 1440px) {
      ${"" /* 6 */}
      width: calc((100vw - 140px) / 6);
    }

    @media (max-width: 1200px) {
      ${"" /* 5 */}
      width: calc((100vw - 120px) / 5);
    }

    @media (max-width: 992px) {
      ${"" /* 4 */}
      width: calc((100vw - 100px) / 4);
    }

    @media (max-width: 768px) {
      ${"" /* 3 */}
      width: calc((100vw - 40px) / 3);
    }

    @media (max-width: 576px) {
      ${"" /* 2 */}
      width: calc((100vw - 30px) / 2);
    }
  `}
`;

DiscordReact.propTypes = {};

DiscordReact.defaultProps = {};

export default DiscordReact;
