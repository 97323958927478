import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const ReactImage = styled.img.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "discord-reacts-modal__detail__react-detail__body__react-image-pane__image"
    )
  };
})`
  ${({ theme: { colors, fonts, fontSizes, lineHeights, space }, reactCode }) => `
    display: block;
    height: auto;
    max-width: 100%;
    position: relative;
    width: 200px;

    &:after {
      background: ${ colors.yellow };
      border-radius: 5px;
      content: "${ reactCode }";
      font-family: ${ fonts.monospace };
      font-size: ${ fontSizes.xSmall };
      line-height: ${ lineHeights.xSmall };
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(100%, -100%);
    }
  `}

  ${media.sm`
    ${ () => `
      && {
        width: 300px;
      }
    `}
  `}
`;

export default ReactImage;
