import React, { useCallback, useMemo } from "react";

import StyledDiscordReact from "./views/DiscordReact";
import DiscordReactImage from "./views/DiscordReactImage";
import DiscordReactName from "./views/DiscordReactName";

const DiscordReact = props => {
  const {
    invert,
    onSelect,
    react
  } = props;

  const {
    image: {
      asset: {
        fluid
      }
    },
    react: reactName
  } = react;

  const handleClick = useCallback(() => {
    onSelect(react);
  }, [onSelect, react]);

  return useMemo(() => (
    <StyledDiscordReact
      onClick={handleClick}
    >
      <DiscordReactImage
        src={fluid.src}
      />
      <DiscordReactName invert={invert}>
        { reactName }
      </DiscordReactName>
    </StyledDiscordReact>
  ), [fluid, handleClick, invert, reactName]);
};

export default DiscordReact;
