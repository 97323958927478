import React from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import styled from "styled-components";
import { KeyboardFocusContainer } from "@zendeskgarden/container-keyboardfocus";

const StyledGlossaryTermLink = styled(Link).attrs(
  props => {
    const {
      className,
      focused
    } = props;

    return {
      className: classNames(
        className,
        "home-page__body__glossary__glossary-term-link", {
          "home-page__body__glossary__glossary-term-link--is-focused": focused
        }
      )
    }
  }
)`
  ${({ theme: { colors, lineHeights }}) => `
    color: ${ colors.white };
    cursor: pointer;
    display: block;
    outline: none;
    text-decoration: none;

    &:active,
    &:hover
    {
      color: ${ colors.yellow };
    }

    // :focus managed by KeyboardFocusContainer
    &.home-page__body__glossary__glossary-term-link--is-focused
    {
      color: ${ colors.yellow };
    }
  `}
`;

const GlossaryTermLink = React.forwardRef(({ focused, ...other }, ref) => (
  <KeyboardFocusContainer>
    {({ getFocusProps, keyboardFocused }) => (
      <StyledGlossaryTermLink
        {...getFocusProps({
          ref,
          ...other,
          focused: (focused || keyboardFocused) ? "true" : undefined
        })}
      />
    )}
  </KeyboardFocusContainer>
));

export default GlossaryTermLink;
