import classNames from "classnames";
import styled from "styled-components";

const Glossary = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "home-page__body__glossary__glossary-term"
    )
  };
})`
`;

export default Glossary;
