import React, { useMemo } from "react";

import StyledYellowBlurLayer from "./views/YellowBlurLayer";

const YellowBlurLayer = props => {

  return useMemo(() => (
      <StyledYellowBlurLayer/>
  ), []);
};

export default YellowBlurLayer;
