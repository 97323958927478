import React from "react";

import ReactImage from "./views/ReactImage";
import ReactImageWrapper from "./views/ReactImageWrapper";
import StyledReactImagePane from "./views/ReactImagePane";

const ReactImagePane = (props) => {
  const { react } = props;

  const {
    image: {
      asset: { fluid },
    },
    react: reactName,
    reactCode,
  } = react;

  // console.log(fluid);

  return (
    <StyledReactImagePane>
      <ReactImageWrapper reactCode={reactCode}>
        <ReactImage alt={reactName} src={fluid.src} />
      </ReactImageWrapper>
    </StyledReactImagePane>
  );
};

export default ReactImagePane;
