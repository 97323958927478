import React from "react";
import classNames from "classnames";
import styled from "styled-components";

const StyledDiscordReactImage = styled.div.attrs((props) => {
  const { className } = props;

  return {
    className: classNames(
      className,
      "discord-reacts-modal__discord-react__image"
    )
  };
})`
  ${() => `
    width: 100px;
    padding: 5px;
    position: relative;
    max-width: 100%;

    &:after {
      border: 2px solid #FFFF00;
      bottom: 0;
      content: "";
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    img {
      width: 100px;
      padding: 5px;
      position: relative;
      max-width: 100%;
    }
  `}
`;

const DiscordReactImage = props => {
  const { alt, src } = props;

  return (
    <StyledDiscordReactImage>
      <img alt={alt} src={src}/>
    </StyledDiscordReactImage>
  );
}

DiscordReactImage.propTypes = {};

DiscordReactImage.defaultProps = {};

export default DiscordReactImage;
