import classNames from "classnames";
import styled from "styled-components";

const DiscordReactsIndex = styled.div.attrs((props) => {
  const { className } = props;

  return {
    className: classNames(
      className,
      "discord-reacts-modal__index__inner-wrapper"
    )
  };
})`
  ${({ theme: { space }}) => `
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    column-gap: ${ space.medium };
    row-gap: ${ space.large };
    justify-items: center;

    @media (max-width: 1440px) {
      grid-template-columns: repeat(6, 1fr);
    }

    @media (max-width: 1200px) {
      grid-template-columns: repeat(5, 1fr);
    }

    @media (max-width: 992px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
      column-gap: ${ space.small };
    }

    @media (max-width: 576px) {
      grid-template-columns: repeat(2, 1fr);
    }
  `}
`;

DiscordReactsIndex.propTypes = {};

DiscordReactsIndex.defaultProps = {};

export default DiscordReactsIndex;
