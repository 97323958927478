import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Metadata } from "@webdex/Core";
import AppHeader from "@webdex/Layouts/App/components/Header";
import { MemeOverlay } from "@webdex/Layouts/App/components/MemeOverlay";

import SubLayoutContext from "@webdex/Layouts/App/subLayouts/contexts/SubLayoutContext";
import { isBrowser } from "@webdex/Utils/Window";
import {
  DiscordReactsModal,
  DiscordReactsModalTogglePane,
} from "@webdex/Workflows/DiscordReactsModal";

import Body from "./components/Body";
import Header from "./components/Header";
import YellowBlurLayer from "./components/YellowBlurLayer";
import StyledHome from "./views/Home";

const Home = (props) => {
  const {
    data: {
      glossaryTerms,
      homePage: { metadata },
    },
  } = props;

  const { isTransitioning, lastScrollHTMLTopBeforeTransition } =
    useContext(SubLayoutContext);

  const [discordReactsModalOpen, setDiscordReactsModalOpen] = useState(false);
  const [stickyOffset, setStickyOffset] = useState(0.0);

  const bodyElRef = useRef(null);
  const headerElRef = useRef(null);
  const isFirstTransitionRef = useRef(true);
  const lastScrollHTMLTopBeforeTransitionRef = useRef(
    lastScrollHTMLTopBeforeTransition
  );

  useEffect(() => {
    lastScrollHTMLTopBeforeTransitionRef.current =
      lastScrollHTMLTopBeforeTransition;
  }, [lastScrollHTMLTopBeforeTransition]);

  useEffect(() => {
    if (!isBrowser() || !bodyElRef.current) {
      return;
    }

    const bodyEl = bodyElRef.current;

    if (isTransitioning) {
      if (isFirstTransitionRef.current === true) {
        isFirstTransitionRef.current = false;
      } else {
        // Exiting.
        const lastScrollHTMLTopBeforeTransition =
          lastScrollHTMLTopBeforeTransitionRef.current;

        bodyEl.style.position = "relative";
        bodyEl.style.top = `-${lastScrollHTMLTopBeforeTransition}px`;
      }
    }

    return () => {
      bodyEl.style.position = "static";
      bodyEl.style.top = `0`;
    };
  }, [isTransitioning]);

  useEffect(() => {
    let headerResizeObserver;

    const headerEl = headerElRef.current;

    if (headerEl) {
      headerResizeObserver = new ResizeObserver((_) => {
        setStickyOffset(headerEl.getBoundingClientRect().bottom);
      });

      headerResizeObserver.observe(headerEl);
    }

    return () => {
      if (!!headerResizeObserver) {
        headerResizeObserver.unobserve(headerEl);
      }
    };
  }, [headerElRef]);

  const handleDiscordReactsModalClosed = useCallback(() => {
    setDiscordReactsModalOpen(false);
  }, []);

  const openDiscordReactsModal = useCallback(() => {
    setDiscordReactsModalOpen(true);
  }, []);

  return useMemo(() => {
    return (
      <React.Fragment>
        <Metadata metadata={metadata} />
        <MemeOverlay imgWidth={200} idleDuration={10000} />
        <AppHeader />
        <StyledHome>
          <YellowBlurLayer />
          <Header ref={headerElRef} />
          <Body
            ref={bodyElRef}
            glossaryTerms={glossaryTerms}
            stickyOffset={stickyOffset}
          />
          <DiscordReactsModalTogglePane onClick={openDiscordReactsModal} />
        </StyledHome>
        <DiscordReactsModal
          onClose={handleDiscordReactsModalClosed}
          open={discordReactsModalOpen}
        />
      </React.Fragment>
    );
  }, [
    bodyElRef,
    discordReactsModalOpen,
    headerElRef,
    handleDiscordReactsModalClosed,
    glossaryTerms,
    metadata,
    openDiscordReactsModal,
    stickyOffset,
  ]);
};

export default Home;
