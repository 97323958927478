import React, { forwardRef } from "react";
import { navigate } from "gatsby";

import { Button } from "@webdex/UI/Button";
import { Row, Col } from "@webdex/UI/Grid";
import { ResponsiveText } from "@webdex/UI/Typography";

import ButtonsWrapper from "./views/ButtonsWrapper";
import StyledHeader from "./views/Header";

const Header = forwardRef((props, ref) => {
  const goToIntroductionText = () => {
    navigate("/introduction");
  };

  const goToIntroductionVideo = () => {
    navigate("/video");
  };

  return (
    <StyledHeader ref={ref}>
      <Row>
        <Col col="8">
          <ButtonsWrapper>
            <Row>
              <Col col="4">
                <Button role="link" onClick={goToIntroductionVideo}>
                  <ResponsiveText
                    as="span"
                    sm="small"
                    xs="xSmall"
                    weight="bold"
                  >
                    Watch Introduction
                  </ResponsiveText>
                </Button>
              </Col>
              <Col col="4">
                <Button role="link" onClick={goToIntroductionText}>
                  <ResponsiveText
                    as="span"
                    sm="small"
                    xs="xSmall"
                    weight="bold"
                  >
                    Read Introduction
                  </ResponsiveText>
                </Button>
              </Col>
            </Row>
          </ButtonsWrapper>
          <ResponsiveText lg="xxLarge" md="xLarge" sm="xLarge" xs="large">
            <ResponsiveText as="span" sm="hide" xs="hide">
              New Models Codex
            </ResponsiveText>Y2K20 Glossary Terms
          </ResponsiveText>
        </Col>
      </Row>
    </StyledHeader>
  );
});

export default Header;
