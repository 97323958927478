import classNames from "classnames";
import styled from "styled-components";

const DiscordReactsIndex = styled.div.attrs((props) => {
  const { className } = props;

  return {
    className: classNames(className, "discord-reacts-modal__index"),
  };
})`
`;

DiscordReactsIndex.propTypes = {};

DiscordReactsIndex.defaultProps = {};

export default DiscordReactsIndex;
