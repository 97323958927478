import React from "react";
import classNames from "classnames";
import ReactDOMServer from "react-dom/server";
import styled, { css, keyframes } from "styled-components";
import { media } from "styled-bootstrap-grid";

import { svg } from "@webdex/UI/Utils";

const gradientKeyframes = keyframes`
   0% { transform: translateX(-20vw) translateY(-35vh); }
   25% { transform: translateX(20vw) translateY(-20vh); }
   50% { transform: translateX(20vw) translateY(-35vh); }
   75% { transform: translateX(-20vw) translateY(-20vh); }
   100% { transform: translateX(-20vw) translateY(-35vh); }
`;

const Gradient = (props) => {
  return (
    <svg width="1000" height="1000" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <radialGradient id="a">
          <stop offset="0" stopColor="#ff0" stopOpacity="0.33" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </radialGradient>
      </defs>
      <ellipse
        cx="350"
        cy="350"
        rx="350"
        ry="200"
        fill="url('#a')"
        transform="rotate(-15 500 300)"
      >
        {/*        <animate
          attributeName="rx"
          values="500;300;500"
          dur="7s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="ry"
          values="250;150;250"
          dur="11s"
          repeatCount="indefinite"
        />*/}
      </ellipse>
    </svg>
  );
};

const RenderedGradient = ReactDOMServer.renderToString(<Gradient />);

const gradientUrl = svg.toDataURL(RenderedGradient);

const YellowBlurLayer = styled.div.attrs((props) => {
  const { className } = props;

  return {
    className: classNames(className, "home-page__yellow-blur-layer"),
  };
})`
  ${({ theme: { zPositions } }) => css`
    //animation: 31s linear infinite alternate ${gradientKeyframes};
    background-attachment: fixed;
    background-image: url(${gradientUrl});
    background-repeat: no-repeat;
    background-position: right -345px bottom -400px;
    bottom: 0;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: ${zPositions.base + zPositions.above + zPositions.above};

    display: none;

    ${media.md`
      display: block
    `}
  `}
`;

YellowBlurLayer.defaultProps = {};

export default YellowBlurLayer;
