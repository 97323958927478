import classNames from "classnames";
import styled from "styled-components";

const GlossaryTermGroupHeader = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "home-page__body__glossary__glossary-term-group-header"
    )
  };
})`
  ${({ theme: { borders, colors, zPositions }, stickyOffset }) => `
    background: ${ colors.black };
    color: ${ colors.white };
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: sticky;
    top: ${ stickyOffset }px;
    z-index: ${ zPositions.base + zPositions.above };

    &:after {
      box-shadow: ${ borders.bottom } ${ colors.white };
      bottom: 0;
      content: "";
      height: 1px;
      left: 0;
      position: absolute;
      right: 0;
    }
  `}
`;

GlossaryTermGroupHeader.defaultProps = {};

export default GlossaryTermGroupHeader;
