import classNames from "classnames";
import styled from "styled-components";

import { ResponsiveText } from "@webdex/UI/Typography";

const ReactTagText = styled(ResponsiveText).attrs(props => {
  const {
    className
  } = props;

  return {
    as: "div",
    className: classNames(
      className,
      "discord-reacts-modal__detail__react-detail__header__react-tag-text"
    )
  };
})`
  ${({ theme: { space } }) => `
    margin-left: ${ space.xSmall };
    vertical-align: top;
    white-space: nowrap;
  `}
`;

ReactTagText.defaultProps = {
  sm: "small",
  xs: "xSmall"
};

export default ReactTagText;
