import classNames from "classnames";
import styled from "styled-components";

import { Row } from "@webdex/UI/Grid";

const ReactDetailBodyRow = styled(Row).attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "discord-reacts-modal__detail__react-detail-body-row"
    )
  };
})`
  ${() => `
    flex: 1;
  `}
`;

export default ReactDetailBodyRow;
