import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const Header = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "home-page__header"
    )
  };
})`
  ${({ theme: { borders, chrome, colors, zPositions } }) => `
    background: ${ colors.black };
    color: ${ colors.white };
    padding-top: ${ chrome.mobile.headerHeight };
    position: sticky;
    top: 0;
    z-index: ${ zPositions.base + zPositions.above + zPositions.above };

    &:after {
      box-shadow: ${ borders.bottom } ${ colors.white };
      bottom: 0;
      content: "";
      height: 1px;
      left: 0;
      position: absolute;
      right: 0;
    }
  `}

  ${media.sm`
    ${ ({ theme: { chrome } }) => `
      && {
        padding-top: ${ chrome.headerHeight };
      }
    `}
  `}
`;

Header.defaultProps = {};

export default Header;
