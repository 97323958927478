import classNames from "classnames";
import styled from "styled-components";

const Header = styled.div.attrs(props => {
  const {
    className,
    invert
  } = props;

  return {
    className: classNames(
      className,
      "discord-reacts-modal__detail__react-detail__header", {
        "discord-reacts-modal__detail__react-detail__header--invert": invert
      }
    )
  };
})`
  ${({ theme: { borders, colors } }) => `
    position: sticky;
    top: 0;

    &:after {
      box-shadow: ${ borders.bottom } ${ colors.black };
      bottom: 0;
      content: "";
      height: 1px;
      left: 0;
      position: absolute;
      right: 0;
    }

    &.discord-reacts-modal__detail__react-detail__header--invert:after {
      box-shadow: ${ borders.bottom } ${ colors.white };
    }
  `}
`;

Header.defaultProps = {};

export default Header;
