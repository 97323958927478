import classNames from "classnames";
import styled from "styled-components";

import { Row } from "@webdex/UI/Grid";

const ReactDetailHeaderRow = styled(Row).attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "discord-reacts-modal__detail__react-detail-header-row"
    )
  };
})`
  ${() => `
    position: sticky;
    top: 70px; // IDK this is a hack sorry!
  `}
`;

export default ReactDetailHeaderRow;
