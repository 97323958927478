import classNames from "classnames";
import styled from "styled-components";

const Glossary = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "home-page__body__glossary"
    )
  };
})`
  ${({ theme: { colors, space } }) => `
    min-height: 100%;
    padding: 0 0 ${ space.xLarge };
  `}
`;

export default Glossary;
