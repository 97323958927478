import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Portal } from "react-portal";

import { Col, Container, Row } from "@webdex/UI/Grid";
import { ResponsiveText } from "@webdex/UI/Typography";

import Backdrop from "./components/Backdrop";
import ReactDetail from "./components/ReactDetail";
import CloseButton from "./views/CloseButton";
import CloseButtonWrapper from "./views/CloseButtonWrapper";
import StyledDiscordReactDetailModal from "./views/DiscordReactDetailModal";

const DiscordReactDetailModal = props => {
  const { afterClose, invert, onClose, open, react } = props;

  const [entered, setEntered] = useState(open);
  const [mounted, setMounted] = useState(open);

  const lastOpenStateRef = useRef()

  useEffect(() => {
    const lastOpenState = lastOpenStateRef.current;

    let toggleTimeout;

    const closeModal = () => {
      setEntered(false);

      toggleTimeout = setTimeout(() => {
        setMounted(false);
        afterClose();
      }, DiscordReactDetailModal.TRANSITION_MS);
    };

    const openModal = () => {
      // Note: You can ignore the discrepency between how `entered` and `mounted`
      // are handled in this method compared to within the `closeModal` method,
      // and how each handle their "notify" calls. These state values are
      // important for enter/exit behaviour of the modal, but not to when user
      // componants may want to know that the modal has _actually_ entered or
      // exited.
      setEntered(true);
      setMounted(true);
    };

    if (lastOpenState !== open) {
      open ? openModal() : closeModal();
    }

    lastOpenStateRef.current = open;

    return () => {
      clearTimeout(toggleTimeout);
    };
  }, [afterClose, open]);

  const handleModalClicked = useCallback(event => {
    // Prevent DiscordReactDetailModal onClick from triggering background
    // element onClick events.
    event.stopPropagation();
  }, []);

  return useMemo(() => {
    if (!mounted) {
      return null;
    }

    return (
      <Portal>
        <Backdrop
          entered={entered}
          transitionMs={DiscordReactDetailModal.TRANSITION_MS}
        >
          <StyledDiscordReactDetailModal
            invert={invert}
            entered={entered}
            onClick={handleModalClicked}
            transitionMs={DiscordReactDetailModal.TRANSITION_MS}
          >
            <CloseButtonWrapper>
              <Container>
                <Row>
                  <Col lg="2" md="8" sm="8" xs="8" lgOffset="6">
                    <CloseButton
                      invert={invert}
                      onClick={onClose}
                    >
                      <ResponsiveText
                        as="span"
                        sm="small"
                        xs="xSmall"
                        weight="bold"
                      >
                        Close
                      </ResponsiveText>
                    </CloseButton>
                  </Col>
                </Row>
              </Container>
            </CloseButtonWrapper>
            <ReactDetail invert={invert} react={react}/>
          </StyledDiscordReactDetailModal>
        </Backdrop>
      </Portal>
    )
  }, [
    entered,
    handleModalClicked,
    invert,
    mounted,
    onClose,
    react
  ]);
};

DiscordReactDetailModal.TRANSITION_MS = 250;

export default DiscordReactDetailModal;
