import classNames from "classnames";
import styled from "styled-components";

const ButtonsWrapper = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "home-page__header__buttons-wrapper"
    )
  };
})`
  ${({ theme: { space } }) => `
    margin: ${ space.small } 0;
  `}
`;

export default ButtonsWrapper;
