import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import FocusLock from "react-focus-lock";
import { CSSTransition } from "react-transition-group";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const TRANSITION_NAME = `discord-reacts-modal`;

const StyledDiscordReactsModal = styled.div.attrs(
  props => {
    const {
      className,
      invert
    } = props;

    return {
      className: classNames("discord-reacts-modal", className, {
        "discord-reacts-modal--invert": invert
      })
    };
  }
)`
  ${({ theme: { chrome, colors }, transitionMs }) => `
    color: ${ colors.black };
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-bottom: ${ chrome.mobile.headerHeight }; // Yes, header, for looks.
    padding-top: ${ chrome.mobile.headerHeight };
    position: relative;
    transition:
      opacity ${ transitionMs }ms cubic-bezier(0.39, 0.575, 0.565, 1),
      transform ${ transitionMs }ms cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-property: filter, opacity, transform;
    width: 100%;

    &.discord-reacts-modal--invert {
      color: ${ colors.white };
    }

    &.${ TRANSITION_NAME }-appear,
    &.${ TRANSITION_NAME }-enter {
      transition-delay: ${ transitionMs }ms;
    }

    &.${ TRANSITION_NAME }-appear,
    &.${ TRANSITION_NAME }-enter {
      opacity: 0;
      transform: scale(0.925);
    };

    &.${ TRANSITION_NAME }-appear.${ TRANSITION_NAME }-appear-active,
    &.${ TRANSITION_NAME }-enter.${ TRANSITION_NAME }-enter-active,
    &.${ TRANSITION_NAME }-appear-done,
    &.${ TRANSITION_NAME }-enter-done,
    &.${ TRANSITION_NAME }-exit {
      opacity: 1;
      transform: scale(1.0);
    };

    &.${ TRANSITION_NAME }-exit.${ TRANSITION_NAME }-exit-active,
    &.${ TRANSITION_NAME }-exit-done {
      opacity: 0;
      transform: scale(0.975);
    };
  `}

  ${media.sm`
    ${ ({ theme: { chrome } }) => `
      && {
        padding-bottom: ${ chrome.headerHeight }; // Yes, header, for looks.
        padding-top: ${ chrome.headerHeight };
      }
    `}
  `}
`;

StyledDiscordReactsModal.propTypes = {};

StyledDiscordReactsModal.defaultProps = {};

const DiscordReactsModal = props => {
  const { children, entered, transitionMs, ...passdownProps } = props;

  return (
    <CSSTransition
      appear
      classNames={{
        appear: `${ TRANSITION_NAME }-appear`,
        appearActive: `${ TRANSITION_NAME }-appear-active`,
        // Keep this. Prevents `react-transition-group` from adding
        // `undefined` to class name list.
        appearDone: `${ TRANSITION_NAME }-appear-done`,
        enter: `${ TRANSITION_NAME }-enter`,
        enterActive: `${ TRANSITION_NAME }-enter-active`,
        enterDone: `${ TRANSITION_NAME }-enter-done`,
        exit: `${ TRANSITION_NAME }-exit`,
        exitActive: `${ TRANSITION_NAME }-exit-active`,
        exitDone: `${ TRANSITION_NAME }-exit-done`,
      }}
      in={ entered }
      timeout={{
        appear: transitionMs, enter: transitionMs, exit: transitionMs
      }}
    >
      <StyledDiscordReactsModal transitionMs={ transitionMs } { ...passdownProps }>
        <FocusLock autoFocus={false}>
          { children }
        </FocusLock>
      </StyledDiscordReactsModal>
    </CSSTransition>
  );
};

DiscordReactsModal.propTypes = {
  invert: PropTypes.bool.isRequired,
  entered: PropTypes.bool.isRequired,
  transitionMs: PropTypes.number.isRequired
};

DiscordReactsModal.defaultProps = {
  invert: false
};

export default DiscordReactsModal;
