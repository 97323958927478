import classNames from "classnames";
import styled from "styled-components";

import { Container } from "@webdex/UI/Grid";

const ReactDetail = styled(Container).attrs(props => {
  const {
    className,
    invert
  } = props;

  return {
    className: classNames(
      className,
      "discord-reacts-modal__detail__react-detail",
      {
        "discord-reacts-modal__detail__react-detail--invert": invert
      }
    )
  };
})`
  ${({ theme: { colors } }) => `
    color: ${ colors.black };
    display: flex;
    flex: 1;
    flex-direction: column;

    &.discord-reacts-modal__detail__react-detail--invert {
      color: ${ colors.white };
    }
  `}
`;

export default ReactDetail;
